@import url(https://fonts.googleapis.com/css?family=Amatic+SC);
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}

body {
  scroll-behavior: smooth;
}

.loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  z-index: -999;
}
.loading1 svg {
  display: block;
  width: 258px;
  height: 258px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #1A1D88;
}

.tree {
  fill: #E73E0D;
}

.circle-mask {
  transform-origin: 50% 90%;
  -webkit-animation: scale 5s infinite ease-out;
          animation: scale 5s infinite ease-out;
}

@-webkit-keyframes scale {

  0%,
  100% {
    transform: scale(0.0);
  }

  7%,
  90% {
    transform: scale(0.4);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes scale {

  0%,
  100% {
    transform: scale(0.0);
  }

  7%,
  90% {
    transform: scale(0.4);
  }

  50% {
    transform: scale(1);
  }
}
.col-33 {
  width: 33.3333333333%;
  float: left;
}

.loading2 {
  background-color: brown;
  position: relative;
  height: 90px;
  width: 90px;
  margin: 80px auto;
  border: none;
}

.square {
  position: absolute;
  height: 80px;
  width: 80px;
  background: #ecf0f1;
  opacity: 0.2;
}


/*Square-C*/
.square-c {
  opacity: 0.8;
  height: 30px;
  width: 30px;
  -webkit-animation: chasingCube 2s infinite ease-in-out;
  animation: chasingCube 2s infinite ease-in-out;
}

.square-c.state2c {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes chasingCube {
  25% {
    -webkit-transform: translateX(60px) rotate(90deg) scale(0.5);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(60px) rotate(180deg);
  }

  75% {
    -webkit-transform: translateX(0px) translateY(60px) rotate(270deg) scale(0.5);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes chasingCube {
  25% {
    transform: translateX(60px) rotate(90deg) scale(0.5);
  }

  50% {
    transform: translateX(60px) translateY(60px) rotate(180deg);
  }

  75% {
    transform: translateX(0px) translateY(60px) rotate(270deg) scale(0.5);
  }

  100% {
    transform: rotate(360deg);
  }
}
.loading3-parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loading3 {
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding-top: 30%;
  margin: 0 auto;
}

.ball {
  width: 10px;
  height: 10px;
  margin: 10px auto;
  border-radius: 50px;
}

.ball:nth-child(1) {
  background: #ffffff;
  -webkit-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
  background: #ffffff;
  -webkit-animation: left 1.1s infinite ease-in-out;
  animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
  background: #ffffff;
  -webkit-animation: right 1.05s infinite ease-in-out;
  animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
  background: #ffffff;
  -webkit-animation: left 1.15s infinite ease-in-out;
  animation: left 1.15s infinite ease-in-out;
}

.ball:nth-child(5) {
  background: #ffffff;
  -webkit-animation: right 1.1s infinite ease-in-out;
  animation: right 1.1s infinite ease-in-out;
}

.ball:nth-child(6) {
  background: #ffffff;
  -webkit-animation: left 1.05s infinite ease-in-out;
  animation: left 1.05s infinite ease-in-out;
}

.ball:nth-child(7) {
  background: #ffffff;
  -webkit-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}

@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px);
  }

  50% {
    -webkit-transform: translate(15px);
  }

  100% {
    -webkit-transform: translate(-15px);
  }
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px);
  }

  50% {
    -webkit-transform: translate(-15px);
  }

  100% {
    -webkit-transform: translate(15px);
  }
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }

  50% {
    transform: translate(15px);
  }

  100% {
    transform: translate(-15px);
  }
}

@keyframes left {
  0% {
    transform: translate(15px);
  }

  50% {
    transform: translate(-15px);
  }

  100% {
    transform: translate(15px);
  }
}
/* 
body {
  background-color: #ffde6b;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
} */

h1 {
  position: relative;
  margin: 0 auto;
  top: 25vh;
  width: 100vw;
  text-align: center;
  font-family: 'Amatic SC';
  font-size: 6vh;
  color: #333;
  opacity: .75;
  -webkit-animation: pulse 2.5s linear infinite;
          animation: pulse 2.5s linear infinite;
}

#cooking {
  position: relative;
  margin: 0 auto;
  top: 0;
  width: 75vh;
  height: 75vh;
  overflow: hidden;
}

#cooking .bubble {
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0.25vh #4d4d4d;
  opacity: 0;
}

#cooking .bubble:nth-child(1) {
  margin-top: 2.5vh;
  left: 58%;
  width: 2.5vh;
  height: 2.5vh;
  background-color: #454545;
  -webkit-animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
          animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#cooking .bubble:nth-child(2) {
  margin-top: 3vh;
  left: 52%;
  width: 2vh;
  height: 2vh;
  background-color: #3d3d3d;
  -webkit-animation: bubble 2s ease-in-out .35s infinite;
          animation: bubble 2s ease-in-out .35s infinite;
}

#cooking .bubble:nth-child(3) {
  margin-top: 1.8vh;
  left: 50%;
  width: 1.5vh;
  height: 1.5vh;
  background-color: #333;
  -webkit-animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
          animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(4) {
  margin-top: 2.7vh;
  left: 56%;
  width: 1.2vh;
  height: 1.2vh;
  background-color: #2b2b2b;
  -webkit-animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.9s infinite;
          animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.9s infinite;
}

#cooking .bubble:nth-child(5) {
  margin-top: 2.7vh;
  left: 63%;
  width: 1.1vh;
  height: 1.1vh;
  background-color: #242424;
  -webkit-animation: bubble 1.6s ease-in-out 1s infinite;
          animation: bubble 1.6s ease-in-out 1s infinite;
}

#cooking #area {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  background-color: transparent;
  transform-origin: 15% 60%;
  -webkit-animation: flip 2.1s ease-in-out infinite;
          animation: flip 2.1s ease-in-out infinite;
}

#cooking #area #sides {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 15% 60%;
  -webkit-animation: switchSide 2.1s ease-in-out infinite;
          animation: switchSide 2.1s ease-in-out infinite;
}

#cooking #area #sides #handle {
  position: absolute;
  bottom: 18%;
  right: 80%;
  width: 35%;
  height: 20%;
  background-color: transparent;
  border-top: 1vh solid #333;
  border-left: 1vh solid transparent;
  border-radius: 100%;
  transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}

#cooking #area #sides #pan {
  position: absolute;
  bottom: 20%;
  right: 30%;
  width: 50%;
  height: 8%;
  background-color: #333;
  border-radius: 0 0 1.4em 1.4em;
  transform-origin: -15% 0;
}

#cooking #area #pancake {
  position: absolute;
  top: 24%;
  width: 100%;
  height: 100%;
  transform: rotateX(85deg);
  -webkit-animation: jump 2.1s ease-in-out infinite;
          animation: jump 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry {
  position: absolute;
  bottom: 26%;
  right: 37%;
  width: 40%;
  height: 45%;
  background-color: #333;
  box-shadow: 0 0 3px 0 #333;
  border-radius: 100%;
  transform-origin: -20% 0;
  -webkit-animation: fly 2.1s ease-in-out infinite;
          animation: fly 2.1s ease-in-out infinite;
}

@-webkit-keyframes jump {
  0% {
    top: 24%;
    transform: rotateX(85deg);
  }

  25% {
    top: 10%;
    transform: rotateX(0deg);
  }

  50% {
    top: 30%;
    transform: rotateX(85deg);
  }

  75% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(85deg);
  }
}

@keyframes jump {
  0% {
    top: 24%;
    transform: rotateX(85deg);
  }

  25% {
    top: 10%;
    transform: rotateX(0deg);
  }

  50% {
    top: 30%;
    transform: rotateX(85deg);
  }

  75% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(85deg);
  }
}

@-webkit-keyframes flip {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(-27deg);
  }

  30%,
  50% {
    transform: rotate(0deg);
  }

  55% {
    transform: rotate(27deg);
  }

  83.3% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes flip {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(-27deg);
  }

  30%,
  50% {
    transform: rotate(0deg);
  }

  55% {
    transform: rotate(27deg);
  }

  83.3% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes switchSide {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@keyframes switchSide {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes fly {
  0% {
    bottom: 26%;
    transform: rotate(0deg);
  }

  10% {
    bottom: 40%;
  }

  50% {
    bottom: 26%;
    transform: rotate(-190deg);
  }

  80% {
    bottom: 40%;
  }

  100% {
    bottom: 26%;
    transform: rotate(0deg);
  }
}

@keyframes fly {
  0% {
    bottom: 26%;
    transform: rotate(0deg);
  }

  10% {
    bottom: 40%;
  }

  50% {
    bottom: 26%;
    transform: rotate(-190deg);
  }

  80% {
    bottom: 40%;
  }

  100% {
    bottom: 26%;
    transform: rotate(0deg);
  }
}

@-webkit-keyframes bubble {
  0% {
    transform: scale(0.15, 0.15);
    top: 80%;
    opacity: 0;
  }

  50% {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }

  100% {
    transform: scale(0.33, 0.33);
    top: 60%;
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    transform: scale(0.15, 0.15);
    top: 80%;
    opacity: 0;
  }

  50% {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }

  100% {
    transform: scale(0.33, 0.33);
    top: 60%;
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: .25;
  }

  50% {
    transform: scale(1.2, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: .25;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: .25;
  }

  50% {
    transform: scale(1.2, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: .25;
  }
}
