.loading3-parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loading3 {
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding-top: 30%;
  margin: 0 auto;
}

.ball {
  width: 10px;
  height: 10px;
  margin: 10px auto;
  border-radius: 50px;
}

.ball:nth-child(1) {
  background: #ffffff;
  -webkit-animation: right 1s infinite ease-in-out;
  -moz-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
  background: #ffffff;
  -webkit-animation: left 1.1s infinite ease-in-out;
  -moz-animation: left 1.1s infinite ease-in-out;
  animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
  background: #ffffff;
  -webkit-animation: right 1.05s infinite ease-in-out;
  -moz-animation: right 1.05s infinite ease-in-out;
  animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
  background: #ffffff;
  -webkit-animation: left 1.15s infinite ease-in-out;
  -moz-animation: left 1.15s infinite ease-in-out;
  animation: left 1.15s infinite ease-in-out;
}

.ball:nth-child(5) {
  background: #ffffff;
  -webkit-animation: right 1.1s infinite ease-in-out;
  -moz-animation: right 1.1s infinite ease-in-out;
  animation: right 1.1s infinite ease-in-out;
}

.ball:nth-child(6) {
  background: #ffffff;
  -webkit-animation: left 1.05s infinite ease-in-out;
  -moz-animation: left 1.05s infinite ease-in-out;
  animation: left 1.05s infinite ease-in-out;
}

.ball:nth-child(7) {
  background: #ffffff;
  -webkit-animation: right 1s infinite ease-in-out;
  -moz-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}

@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px);
  }

  50% {
    -webkit-transform: translate(15px);
  }

  100% {
    -webkit-transform: translate(-15px);
  }
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px);
  }

  50% {
    -webkit-transform: translate(-15px);
  }

  100% {
    -webkit-transform: translate(15px);
  }
}

@-moz-keyframes right {
  0% {
    -moz-transform: translate(-15px);
  }

  50% {
    -moz-transform: translate(15px);
  }

  100% {
    -moz-transform: translate(-15px);
  }
}

@-moz-keyframes left {
  0% {
    -moz-transform: translate(15px);
  }

  50% {
    -moz-transform: translate(-15px);
  }

  100% {
    -moz-transform: translate(15px);
  }
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }

  50% {
    transform: translate(15px);
  }

  100% {
    transform: translate(-15px);
  }
}

@keyframes left {
  0% {
    transform: translate(15px);
  }

  50% {
    transform: translate(-15px);
  }

  100% {
    transform: translate(15px);
  }
}