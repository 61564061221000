body,
* {
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}

body {
  scroll-behavior: smooth;
}
